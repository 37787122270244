import $ from 'jquery';
require("../__vendor/TweenMax.min.js");

const loader = () => {
    
    let loader = $(".loader"),
        loaderLogo = loader.find("svg"),
        loaderTl = new TimelineMax();

    loaderTl.to(loaderLogo, 1, {
        autoAlpha: 1
    }).set(loader, {
        className: "+=loader loader-active"
    }, 1.2).set(loader, {
        className: "+=loader loader-active loader-logo-white"
    }, 1.5).set(loader, {
        className: "+=loader loader-loaded loader-logo-white"
    }, 2.25).set(loader, {
        className: "+=loader loader-loaded loader-lines loader-logo-white"
    }, 2.75).to(loaderLogo, 1, {
        autoAlpha: 0
    }, 2.5).set(loader, {
        className: "+=loader loader-loaded loader-lines loader-logo-white loader-hide-sections"
    }, 3.75).set(loader, {
        className: "+=loader loader-loaded loader-lines loader-logo-white loader-hide-sections loader-hide"
    }, 5);

};

export default loader;