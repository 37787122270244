import "@babel/polyfill";
import $ from 'jquery';
import loader from './__modules/loader';
import slider from './__modules/slider';
import projectSlider from './__modules/projectSlider';
import service from './__modules/service';
require("./__vendor/TweenMax.min.js");
require("./__vendor/numscroller-1.0.js");

document.addEventListener('DOMContentLoaded', () => {
	loader();

	// Slider Init
	if($(".slider").length) {
		slider();
	}

	// Project Slider Init
	if($(".project-slider").length) {
		projectSlider();
	}

	// Service Init
	// if($(".service").length) {
	// 	service();
	// }

	// Menu show and hide effect
	let menuTl = new TimelineMax({
		paused: true
	});
	menuTl.staggerTo(".menu-list li", 0.75, {
		autoAlpha: 1,
		y: 0
	}, 0.1, 1).staggerTo(".language li, .menu--social-media li, .footer--bottom a", 0.5, {
		autoAlpha: 1
	}, 0.1, 1.5);

	let menuCloseTl = new TimelineMax({
		paused: true
	});

	menuCloseTl.staggerTo(".language li, .menu--social-media li, .footer--bottom a", 0.25, {
		autoAlpha: 0
	}, 0.1).staggerTo(".menu-list li", 0.25, {
		autoAlpha: 0,
		y: 20
	}, 0.1, 0.5).set(".menu", {
		className: "+=menu show hide"
	}).set(".menu", {
		className: "+=menu"
	}, 1.85);

	// Siderbar click
	let sidebarClicked = false;

	$(".sidebar").on("click", function(e) {
		e.preventDefault();

		if(!sidebarClicked) {
			sidebarClicked = true;
			
			if($(".menu").hasClass("show")) {
				menuTl.timeScale(3).reverse();
				setTimeout(function() {
					$(".menu").addClass("hide");
				}, 600);
				setTimeout(function() {
					$(".menu").removeClass("show");
					$(".menu").removeClass("hide");
					sidebarClicked = false;
				}, 1600);
			} else {
				$(".menu").addClass("show");
				menuTl.timeScale(1).play();
				sidebarClicked = false;
			}
		}
	});

	$(".sidebar--logo").on("click", function(e) {
		e.stopPropagation();
	});

	// Sidebar scroll top
	$(".sidebar--scroll").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();

		$("html, body").animate({
			scrollTop: 0
		}, 800);
	});

	// About nav click
	$(".nav a").on("click", function(e) {
		e.preventDefault();

		let href = $(this).attr("href");
		$("body, html").animate({
			scrollTop: $(href).offset().top - 50
		}, 700);
	});

	// Menu items text init
	menuSmallItemsInit();

	// Hover menu show text
	$('.menu-list li').hover(
		function() {
			$(".menu-small-list li").eq($(this).index()).stop(false, true).addClass("active");
		},
		function() {
			$(".menu-small-list li").eq($(this).index()).stop(false, true).removeClass("active");
		}
	);
});

window.addEventListener('load', () => {
});

window.addEventListener('resize', () => {
	// Menu items text init
	menuSmallItemsInit();
});

window.addEventListener('scroll', () => {
	if($(window).width() < 992) {
		if($(window).scrollTop() > 0) {
			$(".sidebar").addClass("sidebar--fixed");
		} else {
			$(".sidebar").removeClass("sidebar--fixed");
		}
	}
});

function menuSmallItemsInit() {
	if($(window).width() > 991) {
		$(".menu-small-list li").height($(".menu-list li").height());
	}
}