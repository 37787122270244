import $ from 'jquery';
require("../__vendor/jquery.bxslider.js");

const slider = () => {
    $(".slider ul").bxSlider({
        mode: 'fade',
        speed: 800,
        auto: true,
        pause: 8000,
        pager: true,
        controls: false,
        keyboardEnabled: true,
        adaptiveHeight: true,
    });
};

export default slider;