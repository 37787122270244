import $ from 'jquery';
require("../__vendor/jquery.bxslider.js");

const projectSlider = () => {
	let projectSliderWrapper = $(".project-slider--wrapper"),
		projectSliderThumb = $(".project-slider--thumb"),
		projectSliderText = $(".project-slider--text"),
		projectSliderArrows = $(".project-slider--arrows a");

	projectSliderWrapper.bxSlider({
		mode: 'fade',
		speed: 800,
		pager: false,
		controls: false,
		keyboardEnabled: true,
		onSlideAfter: function($slideElement, oldIndex, newIndex) {
			let newActiveThumb = $(".project-slider--thumb a").eq(newIndex);

			$(".project-slider--thumb .active").removeClass("active");
			newActiveThumb.addClass("active");
			projectSliderText.find("p").text(newActiveThumb.attr("data-text"));
		}
	});

	projectSliderText.find("p").text(projectSliderThumb.find("a").first().attr("data-text"));

	$(".project-slider--thumb a").on("click", function(e) {
		e.preventDefault();

		if(!$(this).hasClass("active")) {
			projectSliderWrapper.goToSlide($(this).index());
		}
	});

	projectSliderArrows.on("click", function(e) {
		e.preventDefault();

		let itemIndex = $(this).index();

		if(itemIndex === 0) {
			projectSliderWrapper.goToPrevSlide();
		} else {
			projectSliderWrapper.goToNextSlide();
		}
	});
};

export default projectSlider;